// Glider Configuration
new Glider(document.querySelector(".carousel-header__container"), {
  slidesToShow: 1,
  slidesToScroll: 1,
  scrollLock: true,
  draggable: false,
  dots: ".dots",
  duration: 2,
})

function translateAriaCarouselHeader() {
  document.querySelectorAll('div.dots button.glider-dot').forEach(button => {
    button.setAttribute('aria-label', `Página ${button.getAttribute('data-index')}`)
  })
}

translateAriaCarouselHeader()